<template>
  <div id="tv">
    <fullscreen v-model="fullscreen">
      <button class="resize-btn" @click="fullscreen = !fullscreen">
        <v-icon size="32">mdi-fullscreen</v-icon>
      </button>
      <div
        class="text-center code-container d-flex flex-column"
        v-if="!isConnectionEstablished"
      >
        <div class="code-banner primary d-flex justify-center align-center">
          <div class="text-center img-container">
            <img
              class="cursor-pointer"
              @click="$router.push('/')"
              :src="require('@/assets/images/global/frame.svg')"
              alt=""
            />
          </div>
        </div>

        <section
          class="code-section d-flex flex-column align-center justify-center"
        >
          <div class="code d-flex justify-end">
            {{ code }}
          </div>
          <p class="note bg-transparent">
            Add this code into the screen code field.
          </p>
        </section>
      </div>
      <!-- items -->
      <!-- :class="[isRotated ? `rotate-${rotation}` : '']" -->
      <div v-else-if="isConnectionEstablished" :key="pageKey">
        <div
          v-if="!groups[activeGroupIndex] || noProducts"
          class="d-flex justify-center align-center h-screen font-45"
        >
          Connected!
        </div>

        <div class="w-100" v-else>
          <!-- templates -->
          <div style="width: 100%; height: 100vh">
            <theme-layout
              v-if="groups.length"
              :products="
                filterProducts(groups[activeGroupIndex].groupId.products)
              "
              :logo="logo"
              :currentGroup="groups[activeGroupIndex].groupId"
              :currency="currency.symbol"
              :active-theme="groups[activeGroupIndex].groupId.theme"
              :next-group-trigger="nextGroupTrigger"
              @goNext="goNextHandler"
              @clear-timeouts="clearTimeouts"
            ></theme-layout>
          </div>
        </div>
      </div>

      <div
        v-if="fitTextLoader"
        class="loader-overlay d-flex align-center justify-center"
        :style="`background-color: ${groups[activeGroupIndex].groupId.theme.container.color}`"
      >
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
      </div>
    </fullscreen>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import ThemeLayout from "@/components/themes/ThemeLayout.vue";
import NoSleep from "nosleep.js";
import { io } from "socket.io-client";
export default {
  components: {
    ThemeLayout,
  },
  directives: { mask },
  data: () => ({
    amexCardMask: "XX XX XX",
    code: "",
    MenuSlidesActive: false,
    fullscreen: false,
    products: [],
    groups: [],
    themeNumber: 1,
    themeNumberTemplate: 1,
    preview: {},
    activeGroupIndex: 0,
    themeChangedKey: Math.random(),
    // currency: "",
    logo: "",
    pageKey: Math.random() * 1000,
    noProducts: false,
    connection: null,
    currency: {},
    isConnectionEstablished: false,
    isRotated: false,
    rotation: 0,
    nextGroupTrigger: Math.random(),
  }),
  computed: {
    ...mapGetters(["toggleLayout", "themes"]),
    // currencySymbol() {
    //   return this.$store.state.profile?.currency?.symbol ?? "$$";
    // },
  },
  methods: {
    async goNextHandler() {
      // console.log("go next");
      // only trigger the goNext logic if there are more than one group
      if (this.groups.length > 1) {
        if (this.activeGroupIndex >= this.groups.length - 1) {
          this.activeGroupIndex = 0;
        } else {
          this.activeGroupIndex += 1;
        }

        this.nextGroupTrigger += Math.random();
      } else {
        this.activeGroupIndex = 0;

        if (
          this.groups[0].groupId &&
          this.filterProducts(this.groups[0].groupId.products).length !== 1
        ) {
          this.nextGroupTrigger += Math.random();
        }
      }
    },
    enterCode() {
      if (this.code !== this.$route.params.code) {
        this.codeWithRemoveSpaces = this.code.replace(/\s/g, "");
        this.$router
          .replace({
            path: "/code/" + this.codeWithRemoveSpaces,
          })
          .catch(() => {}); // to mute "Avoided redundant navigation to current location" console error
      }
      this.initTv();
    },
    async checkPreviews(groups = this.groups) {
      return new Promise((resolve, reject) => {
        let filteredGroups = groups.filter(
          (group) => group.groupId.products.length > 0
        );

        filteredGroups = filteredGroups.filter((group) => {
          if (group.groupId.startDate && group.groupId.endDate) {
            return !this.isOutOfDateRange(
              group.groupId.startDate,
              group.groupId.endDate
            );
          }

          return true;
        });

        // give each product a uid
        filteredGroups;

        if (
          filteredGroups.length > 0 &&
          filteredGroups.every(
            (group) => this.filterProducts(group.groupId.products).length > 0
          )
        ) {
          console.log(
            filteredGroups,
            this.filterProducts(filteredGroups[0].groupId.products)
          );
          this.noProducts = false;
          this.groups = filteredGroups;
          this.themeChangedKey = !this.themeChangedKey;
          this.initTv();

          resolve();
        } else {
          this.noProducts = true;
          this.$store.dispatch("toggleLayout", false);
          this.pageKey = Math.random() * 1000;

          reject();
        }
      });
    },
    async initTv() {
      // this.getPreviews()
      //   .then(() => {
      if (!this.noProducts) {
        this.pageKey = Math.random() * 1000;
        // this.fullscreen = true;
        //console.log(this.$fullscreen);
      }
      // })
      // .catch(() => {});
    },
    filterProducts(products) {
      return products.filter((product) => {
        return !product.item?.deleted && product.item?.active;
      });
    },
    initSocketWithListeners(connectionId = null) {
      console.log("creating socket connection");
      let query;
      if (connectionId) {
        query = {
          connectionId,
          type: "1",
        };
      } else {
        query = {
          code: this.code,
          type: "1",
        };
      }

      this.connection = io(process.env.VUE_APP_BASE_URL, {
        query,
        transports: ["polling", "websocket"],
        upgrades: ["websocket"],
        pingTimeout: 20000,
        pingInterval: 250000,
        maxPayload: 1000000,
        reconnection: true,
      });

      this.connection.io.on("reconnect_attempt", (attempt) => {
        console.log({ attempt });
      });

      this.connection.io.on("reconnect_error", (error) => {
        console.log({ error });
      });

      this.connection.io.on("reconnect_failed", () => {
        console.log("reconnect failed");
      });

      this.connection.io.on("error", (error) => {
        console.log("error: ", error);
      });

      this.connection.io.on("ping", () => {
        console.log("ping");
      });

      this.connection.on("connect_error", (err) => {
        console.log("err: ", err);
      });

      this.connection.on("connect", (event) => {
        console.log("connected");
      });

      this.connection.on("open", (event) => {
        console.log("open", event);
      });

      // first event right after pairing
      this.connection.on("startLivePreview", (event) => {
        console.log("startLivePreview", event);

        this.activeGroupIndex = 0;
        // clean up fit text cache...
        this.$store.dispatch("cleanFitTextCache");

        // in case of unpairing
        if (event.preview === null) {
          this.groups = [];
          this.noProducts = true;
          this.isConnectionEstablished = false;

          this.generateCodeAndConnect();

          return;
        }

        this.isConnectionEstablished = true;

        this.groups = event.preview.groups;
        this.currency = event.currency;
        // this.isRotated = event.preview.isRotated;
        // this.rotation = event.preview.rotation;

        this.checkPreviews(event.preview.groups);

        // store connectionId in localStorage
        if (event.connectionId) {
          localStorage.setItem("connectionId", event.connectionId);
        }
      });

      // when a product is updated
      this.connection.on("updateResource", (event) => {
        console.log("updateResource", event);

        // update product
        if (event.resourceType === "item") {
          // clean fit text cache
          this.$store.dispatch("cleanFitTextCache", event.itemId);
          this.groups = this.groups.map((group) => {
            if (group.groupId._id == event.groupId) {
              group.groupId.products = group.groupId.products.map((product) => {
                if (product.item._id == event.itemId) {
                  product.item = event.item;
                }

                return product;
              });
            }

            return group;
          });
        }

        // update group
        if (event.resourceType === "group") {
          // clean up fit text cache...
          this.$store.dispatch("cleanFitTextCache");

          this.groups = this.groups.map((group) => {
            if (group.groupId._id == event.groupId) {
              group.groupId = event.group;
            }

            return group;
          });
        }

        // update theme
        if (event.resourceType === "theme") {
          // clean up fit text cache... just for precaution
          this.$store.dispatch("cleanFitTextCache");

          this.groups = this.groups.map((group) => {
            if (group.groupId._id === event.groupId) {
              group.groupId.theme = event.theme;
            }

            return group;
          });
        }

        // update preview
        if (event.resourceType === "preview") {
          // clean up fit text cache...
          this.$store.dispatch("cleanFitTextCache");
          this.activeGroupIndex = 0;
          this.groups = event.preview.groups;
          // this.isRotated = event.preview.isRotated;
          // this.rotation = event.preview.rotation;
        }

        this.checkPreviews();
      });

      // when a product is inserted
      this.connection.on("insertResource", (event) => {
        console.log("insertResource", event);

        // insert product
        if (event.resourceType === "item") {
          this.groups = this.groups.map((group) => {
            if (group.groupId._id == event.groupId) {
              let sort = event.sort
                ? event.sort.sort
                : group.groupId.products.length;

              group.groupId.products.splice(sort, 0, {
                item: event.item,
                sort: sort,
              });

              // sort
              group.groupId.products.sort((a, b) => {
                return a.sort - b.sort;
              });
            }

            return group;
          });
        }

        // insert group
        if (event.resourceType === "group") {
          this.activeGroupIndex = 0;
          this.groups.push({
            groupId: event.group,
          });
        }

        this.checkPreviews();
      });

      // when a product is deleted
      this.connection.on("deleteResource", (event) => {
        console.log("deleteResource", event);

        // delete product
        if (event.resourceType === "item") {
          // clean up fit text cache...
          this.$store.dispatch("cleanFitTextCache", event.itemId);

          this.groups = this.groups.map((group) => {
            if (group.groupId._id == event.groupId) {
              // delete from products array
              group.groupId.products = group.groupId.products.filter(
                (product) => product.item._id != event.itemId
              );
            }

            return group;
          });
        }

        // delete group
        if (event.resourceType === "group") {
          // clean up fit text cache...
          this.$store.dispatch("cleanFitTextCache");
          this.activeGroupIndex = 0;
          this.groups = this.groups.filter(
            (group) => group.groupId._id != event.groupId
          );
        }

        this.checkPreviews();
      });
    },
    async generateCodeAndConnect() {
      console.log("generating code and creating socket connection");
      let { data } = await this.$axios.get(process.env.VUE_APP_GENERATED_CODE);
      this.code = data.code;
      this.initSocketWithListeners();
    },
    displayFullscreenButton() {
      let tv = document.documentElement;
      let timer;

      tv.addEventListener("mousemove", () => {
        let resizeBtn = document.querySelector(".resize-btn");
        resizeBtn.classList.add("active");
        tv.style.cursor = "default";

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          resizeBtn.classList.remove("active");
          tv.style.cursor = "none";
        }, 1000);
      });
    },
    clearTimeouts(timeouts = this.timeouts) {
      timeouts.forEach((timeout, i, arr) => {
        clearTimeout(timeout);
      });
    },
  },
  async created() {
    let connectionId = localStorage.getItem("connectionId");
    // check if connectionId exists in localStorage
    if (connectionId) {
      console.log("found connection id");
      // check if it exists with isConnectionExists request
      this.$axios
        .get(
          `${process.env.VUE_APP_IS_CONNECTION_EXIST}?connectionId=${connectionId}`
        )
        .then((res) => {
          if (res.data.isExist) {
            console.log("connection exists");
            this.initSocketWithListeners(connectionId);
          } else {
            console.log("connection doesn't exist");
            this.generateCodeAndConnect();
          }
        })
        .catch((err) => {
          console.error({ err });
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong",
            color: "error",
          });
        });
    } else {
      console.log("did not find connection id");
      this.generateCodeAndConnect();
    }
  },
  destroyed() {
    this.$store.dispatch("toggleLayout", true);
    // clean up fit text cache...
    this.$store.dispatch("cleanFitTextCache");
  },
  mounted() {
    let noSleep = new NoSleep();
    noSleep.enable();

    this.displayFullscreenButton();
  },
};
</script>

<style lang="scss">
@import "@/assets/style/code-page.scss";
.code {
  border: 1px solid #ccc;
  border-radius: 39px;
  padding: 20px 30px;
  margin-bottom: 3rem;
  color: #000;
  font-size: 10vw;
  font-weight: 700;
}
.note {
  color: #000;
  font-size: 3vw;
  font-weight: 700;
}
.resize-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  border-radius: 50%;
  // border: 2px solid black;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s;

  &:hover {
    background-color: white;
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
}
</style>
